import React from "react";

import Layout from "../../components/Layout";
import NewsRoll from "../../components/NewsRoll";
import { Helmet } from "react-helmet";
import useSiteMetadata from "./../../components/SiteMetadata";

const NewsIndexPage = () => {
  const { title } = useSiteMetadata();
  return (
    <Layout>
      <div
        className="full-width-image-container margin-top-0"
        style={{ backgroundColor: "#90241f" }}
      >
        <Helmet titleTemplate={`%s | ${title}`}>
          <title>{`Новини`}</title>
        </Helmet>
        <h1
          className="has-text-weight-bold is-size-1"
          style={{
            color: "white",
            padding: "1rem",
          }}
        >
          Новини
        </h1>
      </div>
      <section className="section">
        <div className="container">
          <div className="content">
            <NewsRoll />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NewsIndexPage;
